<template>
  <v-expansion-panels class="theme-expansion">
    <v-expansion-panel v-for="(item, i) in allGames" :key="i">
      <v-expansion-panel-header>
        <v-row>
          <!-- URL Id -->
          <v-col :cols="6" class="pt-4">
            {{ item.urlId }}
          </v-col>

          <!-- Share Icon -->
          <v-col :cols="6" class="text-caption" align="">
            <span v-if="item.createdDate">{{
              formatCreatedDate(item.createdDate)
            }}</span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <game-share-card :urlId="item.urlId" :expiry="item.expiry" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import utilityFunctions from "../assets/js/utility-functions";
import GameShareCard from "./GameShareCard.vue";

export default {
  components: {
    GameShareCard,
  },
  props: { allGames: { type: Array } },
  methods: {
    formatCreatedDate: function (date) {
      return `${utilityFunctions.formatDuration(date, new Date(), true)} ago`;
    },
  },
};
</script>

<style>
.theme-expansion.v-item-group {
  border: solid 1px var(--v-secondary-base);
}

.theme-expansion.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
  color: var(--v-text-base);
}

.theme-expansion .v-expansion-panel::before {
  box-shadow: none;
}

.theme-expansion i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: var(--v-text-base);
}
</style>
