<template>
  <v-card class="mx-auto pa-4" max-width="344" outlined>
    <div class="text--text text-left mb-3">
      <div class="mb-1 text-h6">{{ `Game #${urlId}` }}</div>
      <div class="text-caption">{{ `Expires ${formatTime(expiry)}` }}</div>
    </div>

    <!-- Game link -->
    <div class="d-flex mb-3">
      <v-text-field
        :value="gameUrl"
        class="mb-2 text-caption"
        placeholder="Game URL"
        readonly
        solo
        dense
        hide-details
      ></v-text-field>

      <!-- Copy button -->
      <v-btn
        class="mt-0 ml-1 px-0"
        color="accent"
        text
        @click="copyTextToClipboard"
      >
        Copy
      </v-btn>
    </div>

    <v-divider class="mb-3" dark></v-divider>

    <div>
      <!-- Share game url button -->
      <v-btn class="mr-3" color="accent" small @click="openShareDialog" outlined
        ><v-icon class="mr-1">mdi-share-variant</v-icon> Share
      </v-btn>

      <!-- Go to game button -->
      <v-btn color="accent" small @click="goToGame" outlined> Play Now </v-btn>
    </div>

    <!-- Overlay for when "Copy" button is pressed -->
    <v-overlay absolute :value="showOverlay" :opacity="0.9">
      <v-chip class="ma-2" color="accent" label small>
        <v-icon small class="mr-2">mdi-content-paste</v-icon> Copied
      </v-chip>
    </v-overlay>
  </v-card>
</template>

<script>
import { shareMessage } from "../assets/js/app-strings";
export default {
  props: {
    urlId: { type: String },
    expiry: { type: String },
  },
  data: () => {
    return {
      showOverlay: false,
    };
  },
  computed: {
    gameUrl: function () {
      return this.buildGameUrl(this.urlId);
    },
  },
  methods: {
    buildGameUrl: function (urlId) {
      const gameRoute = this.$router.options.routes.find(
        (r) => r.name === "GamePage"
      );

      return (
        new URL(location.href).origin + gameRoute.path.replace(":urlId", urlId)
      );
    },
    copyTextToClipboard() {
      navigator.clipboard.writeText(this.gameUrl);
      this.toggleOverlay();
    },
    openShareDialog() {
      navigator.share({
        title: shareMessage.title,
        url: this.gameUrl,
      });
    },
    goToGame: function () {
      this.$router.push({ path: `/game/${this.urlId}` });
    },
    formatTime(dateString) {
      let date = new Date(dateString);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleTimeString("en-gb", options);
    },
    toggleOverlay() {
      this.showOverlay = true;

      setTimeout(() => {
        this.showOverlay = false;
      }, 800);
    },
  },
};
</script>
