<template>
  <v-container class="text-center pa-6">
    <div class="text--text">
      <v-icon class="text-h1 mb-3" color="text">{{ iconValue }}</v-icon>
      <div class="text-h5 mb-12">{{ message }}</div>
      <v-btn color="accent" @click="goHome">Go back home</v-btn>
    </div>
  </v-container>
</template>

<script>
import { errorMessages } from "../assets/js/app-strings";
export default {
  props: {
    errorType: { type: String, default: "page-not-found" },
  },
  computed: {
    iconValue: function () {
      switch (this.errorType) {
        case "game-expired":
          return "mdi-emoticon-neutral";

        case "page-not-found":
          return "mdi-emoticon-sad";

        default:
          return "mdi-emoticon-sad";
      }
    },
    message: function () {
      switch (this.errorType) {
        case "game-expired":
          return errorMessages.gameExpired;

        case "page-not-found":
          return errorMessages.pageNotFound;

        default:
          return errorMessages.genericError;
      }
    },
  },
  methods: {
    goHome: function () {
      this.$router.push({ path: `/` });
    },
  },
};
</script>
