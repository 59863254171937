<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <div>{{ getAppName }}</div>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      class="text-center py-8"
    >
      <!-- Game data -->
      <img width="100" :src="gameImage" />
      <div class="text-h6">{{ getAppName }}</div>

      <v-divider class="mx-8 my-6"></v-divider>

      <!-- Action buttons -->
      <div class="my-8" v-if="showActionButton">
        <v-btn color="primary" @click="onActionButtonClicked">{{
          buttonLabel
        }}</v-btn>
      </div>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { appName } from "./assets/js/app-settings";
import { EventBus } from "./plugins/event-bus";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    showActionButton: false,
    buttonLabel: "",
    buttonClickEvent: "",
  }),
  mounted() {
    EventBus.$on("navDrawerActionButtonShow", ({ label, clickEvent }) => {
      this.buttonLabel = label;
      this.buttonClickEvent = clickEvent;
      this.showActionButton = true;
    });

    EventBus.$on("navDrawerActionButtonHide", () => {
      this.buttonLabel = "";
      this.buttonClickEvent = "";
      this.showActionButton = false;
    });
  },
  computed: {
    getAppName: function () {
      return appName;
    },
    gameImage: function () {
      const gameType = "ticTacTwo";
      switch (gameType) {
        case "ticTacTwo":
          return require("./assets/images/tic-tac-two.svg");

        default:
          return "";
      }
    },
  },
  methods: {
    onActionButtonClicked: function () {
      EventBus.$emit(this.buttonClickEvent, {});
      this.drawer = false;
    },
  },
};
</script>

<style>
.v-main {
  background-color: var(--v-background-base);
}
</style>
