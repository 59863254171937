function groupCellsToRows(cells) {
  let rows = [];
  let group = 0;

  for (let i = 0; i < cells.length; i++) {
    if (i - group * 3 === 3) {
      group++;
    }

    // Add new empty row
    if (rows.length <= group) {
      rows.push([]);
    }

    rows[group].push(cells[i]);
  }

  return rows;
}

function getAbsoluteCellIndex(rowIndex, cellIndex) {
  return rowIndex * 3 + cellIndex;
}

module.exports = {
  groupCellsToRows,
  getAbsoluteCellIndex,
};
