import io from "socket.io-client";
import { serverDomain } from "./app-settings";

let sockets = {};

function initialiseSocket(namespace, options) {
  if (namespace in sockets && sockets[namespace] !== null) {
    return sockets[namespace];
  }

  // Prevent reusing connection as query parameters are not passed
  options.forceNew = true;

  sockets[namespace] = io.connect(serverDomain + namespace, options);

  return sockets[namespace];
}

function disconnect(namespace) {
  if (namespace && namespace in sockets) {
    sockets[namespace].disconnect();
  }
}

function broadcastData(namespace, event, data) {
  if (event && sockets[namespace]) {
    sockets[namespace].emit(event, data);
  }
}

export default {
  broadcastData,
  initialiseSocket,
  disconnect,
};
