<template>
  <div class="grid-group">
    <v-row
      v-for="(row, r) in formattedBoard"
      :key="`gg-row-${r}`"
      class="grid-row"
      no-gutters
    >
      <v-col
        v-for="(cell, c) in row"
        :key="`gg-cell-${c}`"
        class="mini-grid"
        :class="{
          'grid-row-top': [0, 1, 2].includes(3 * r + c),
          'grid-col-right': [2, 5, 8].includes(3 * r + c),
          'grid-row-bottom': [6, 7, 8].includes(3 * r + c),
          'grid-col-left': [0, 3, 6].includes(3 * r + c),
          'highlighted-grid': highlightedGrids.includes(3 * r + c),
        }"
      >
        <div
          v-if="
            moveCounter &&
            miniBoardSummary[3 * r + c] !== null &&
            miniBoardSummary[3 * r + c] !== -1
          "
          class="text-center full-grid-icon"
        >
          <v-icon>{{ getGamePiece(miniBoardSummary[3 * r + c]) }}</v-icon>
        </div>
        <mini-grid
          v-else
          :matchIndex="matchIndex"
          :boardIndex="getAbsoluteCellIndex(r, c)"
          :boardProp="cell"
          :gamePieces="gamePieces"
          @on-cell-click="onCellClick"
        />
      </v-col>
    </v-row>

    <v-overlay absolute :value="isWaiting" :opacity="0.8" color="secondary">
      <waiting-animation class="mb-3"></waiting-animation>
      <div class="text-h5">{{ waitingOnPlayerMessage }}</div>
    </v-overlay>
  </div>
</template>

<script>
import {
  getAbsoluteCellIndex,
  groupCellsToRows,
} from "../assets/js/grid-functions";
import miniGrid from "./MiniGrid.vue";
import WaitingAnimation from "./WaitingAnimation.vue";
import { EventBus } from "../plugins/event-bus";
import { gameMessages } from "../assets/js/app-strings";

export default {
  components: { miniGrid, WaitingAnimation },
  props: {
    matchIndex: { type: Number },
    boardProp: { type: Array },
    miniBoardSummary: { type: Array },
    gamePieces: { type: Array },
    winPath: { type: Array },
    isWaiting: { type: Boolean },
  },
  data: () => ({
    highlightedGrids: [],
    moveCounter: 1,
  }),
  computed: {
    formattedBoard: function () {
      return groupCellsToRows(this.boardProp);
    },
    waitingOnPlayerMessage: function () {
      return gameMessages.waitingOnPlayer;
    },
  },
  methods: {
    onCellClick: function (indexes) {
      this.$emit("on-cell-click", indexes);
    },
    getAbsoluteCellIndex: getAbsoluteCellIndex,
    getGamePiece: function (playerIndex) {
      return `mdi-${this.gamePieces[playerIndex]}`;
    },
  },
  mounted() {
    // Initialise any highlighted grids
    if (this.winPath && this.winPath.length > 0) {
      this.highlightedGrids = this.winPath;
    }

    EventBus.$on(
      "boardChange",
      ({ matchIndex, boardIndex, miniBoardSummary }) => {
        if (this.matchIndex === matchIndex) {
          if (
            miniBoardSummary[boardIndex] !== this.miniBoardSummary[boardIndex]
          ) {
            this.miniBoardSummary[boardIndex] = miniBoardSummary[boardIndex];
            this.moveCounter++; // HACKY
          }
        }
      }
    );

    EventBus.$on("matchWin", ({ matchIndex, winPath }) => {
      if (this.matchIndex === matchIndex && winPath) {
        this.highlightedGrids = winPath;
      }
    });
  },
};
</script>

<style>
.grid-group {
  position: relative;
  display: table;
  background-color: var(--v-background-base);
  color: var(--v-text-base);
}

.mini-grid {
  display: table-cell;
  border-width: 1px;
  border-style: solid;
  border-color: var(--v-accent-base);
}

.grid-row-top {
  border-top: none;
}

.grid-col-left {
  border-left: none;
}

.grid-row-bottom {
  border-bottom: none;
}

.grid-col-right {
  border-right: none;
}

.full-grid-icon .v-icon {
  margin: 7px;
  font-size: 7em;
  color: var(--v-text-base);
}

.highlighted-grid {
  background-color: var(--v-accent-base);
}

@media only screen and (max-width: 385px) {
  .row.grid-row {
    flex-wrap: nowrap;
  }

  .full-grid-icon .v-icon {
    margin: 12px;
    font-size: 5em;
  }
}
</style>
