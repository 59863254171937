<template>
  <v-container class="py-0">
    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <!-- Loading -->
          <div v-if="!isPageLoaded" class="text-center my-6 pa-6">
            <v-progress-circular
              indeterminate
              color="accent"
              class="mb-4"
            ></v-progress-circular>
            <div class="text-h5 text--text">Loading</div>
          </div>

          <!-- Game is expired -->
          <div v-else-if="isExpired" class="mt-6">
            <error-page :errorType="`game-expired`" />
          </div>

          <!-- Not logged in to game -->
          <div v-else-if="!isLoggedIn">
            <game-login :gameData="gameData" @playerLoggedIn="onPlayerLogin" />
          </div>

          <!-- Game page -->
          <div v-else>
            <component
              justify-center
              :is="activeGameComponent.type"
              v-bind="activeGameComponent.data.props"
              v-on="activeGameComponent.data.events"
            ></component>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { restApiEndpoints, serverDomain } from "../assets/js/app-settings";
import storageManager from "../assets/js/storage-manager";
import TicTacTwo from "./TicTacTwo.vue";
import GameLogin from "./GameLogin.vue";
import ErrorPage from "./ErrorPage.vue";

export default {
  components: {
    GameLogin,
    TicTacTwo,
    ErrorPage,
  },
  name: "GameManager",
  data: () => ({
    isPageLoaded: false,
    isLoggedIn: false,
    isExpired: false,
    gameData: {},
    playerId: null,
  }),
  computed: {
    activeGameComponent: function () {
      return {
        type: "tic-tac-two",
        data: {
          props: {
            gameId: this.gameData._id,
          },
        },
      };
    },
  },
  methods: {
    async getData() {
      try {
        await axios
          .get(
            serverDomain +
              restApiEndpoints.gameFetch.replace(
                ":urlId",
                this.$route.params.urlId
              )
          )
          .then((response) => {
            return response;
          })
          .then((response) => {
            // Game expired
            if (response.data.status === "expired") {
              this.isExpired = true;
              this.isPageLoaded = true;
            } else if (response.data.status === "active") {
              this.checkIfLoggedIn(response.data._id);
              this.gameData = response.data;
            }
          });
      } catch (error) {
        this.$router.push({ name: "ErrorPage", params: {} });
      }
    },
    async checkIfLoggedIn(gameId) {
      // Check if there is a token for this game
      if (!storageManager.getData(`gameAuthToken.${gameId}`)) {
        this.isPageLoaded = true;
        this.isLoggedIn = false;
        return;
      }

      // Check if token is valid for this game
      try {
        await axios
          .post(serverDomain + restApiEndpoints.gameLoginCheck, {
            gameId,
            token: storageManager.getData(`gameAuthToken.${gameId}`),
          })
          .then((response) => {
            this.isPageLoaded = true;
            this.isLoggedIn = true;
            this.playerId = response.data.playerId;
          });
      } catch (error) {
        this.isPageLoaded = true;
        this.isLoggedIn = false;
      }
    },
    onPlayerLogin: function () {
      this.isPageLoaded = true;
      this.isLoggedIn = true;
    },
  },
  created() {
    this.getData();
  },
};
</script>
