<template>
  <v-container class="scoreboard py-0">
    <v-row justify="center" align="center">
      <v-col cols="4" class="pt-0">
        <player-card
          v-if="orderedPlayers.list[0]"
          :activePlayerId="showCurrentPlayer ? activePlayerId : null"
          :playerId="orderedPlayers.list[0]._id"
          :playerName="orderedPlayers.list[0].name"
          :gamePiece="orderedPlayers.list[0].piece"
        />
      </v-col>
      <v-col cols="4" class="text-center">
        <!-- Game score -->
        <div class="text-h4">{{ score }}</div>

        <!-- Match timer -->
        <div
          v-if="showMatchTimer && currentMatchTimerStart !== null"
          class="text-caption mt-1"
        >
          <timer :startTime="currentMatchTimerStart" />
        </div>

        <!-- Go to summary button -->
        <div v-if="showSummaryButton" class="mt-3">
          <v-btn outlined small color="accent" @click="showSummary"
            >Summary</v-btn
          >
        </div>
      </v-col>
      <v-col cols="4" class="pt-0">
        <player-card
          v-if="orderedPlayers.list[1]"
          :activePlayerId="showCurrentPlayer ? activePlayerId : null"
          :playerId="orderedPlayers.list[1]._id"
          :playerName="orderedPlayers.list[1].name"
          :gamePiece="orderedPlayers.list[1].piece"
        />
        <waiting-animation v-else />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PlayerCard from "./PlayerCard.vue";
import { EventBus } from "../plugins/event-bus";
import Timer from "./Timer.vue";
import WaitingAnimation from "./WaitingAnimation.vue";

export default {
  components: { PlayerCard, Timer, WaitingAnimation },
  props: {
    playerId: { type: String },
    gameState: { type: Object },
    showCurrentPlayer: { type: Boolean, default: true },
    showMatchTimer: { type: Boolean, default: false },
    showSummaryButton: { type: Boolean, default: false },
  },
  data() {
    return {
      activePlayerId: null,
    };
  },
  mounted() {
    this.setActivePlayerId();

    EventBus.$on("scoreboardChange", () => {
      this.setActivePlayerId();
    });
  },
  computed: {
    score: function () {
      const matchScore = this.gameState.data.matchScore;
      return `${matchScore[this.orderedPlayers.isReversed ? 1 : 0]} : ${
        matchScore[this.orderedPlayers.isReversed ? 0 : 1]
      }`;
    },
    orderedPlayers: function () {
      let orderedPlayers = [...this.gameState.players];
      let isReversed = false;

      if (orderedPlayers[0]._id !== this.playerId) {
        orderedPlayers.reverse();
        isReversed = true;
      }

      return { list: orderedPlayers, isReversed };
    },
    currentMatchIndex: function () {
      return this.gameState.data.matches.length - 1;
    },
    currentMatchTimerStart: function () {
      const currentMatch = this.gameState.data.matches[this.currentMatchIndex];
      if (currentMatch.isMatchOver || currentMatch.startTime === null) {
        return null;
      }
      return new Date(currentMatch.startTime);
    },
  },
  methods: {
    setActivePlayerId: function () {
      if (this.gameState.players.length < this.gameState.config.totalPlayers) {
        return null;
      }

      this.activePlayerId =
        this.gameState.players[
          this.gameState.data.matches[this.currentMatchIndex].currentPlayerIndex
        ]._id;
    },
    showSummary: function () {
      this.$emit("show-summary");
    },
  },
};
</script>

<style>
.scoreboard {
  color: var(--v-text-base);
}
</style>
