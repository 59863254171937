<template>
  <v-container
    class="text-center pt-0"
    :class="{ active: activePlayerId === playerId }"
  >
    <div class="text-h3 mb-2">
      <v-icon large color="text">{{ gamePieceIcon }}</v-icon>
    </div>
    <div class="font-weight-thin">{{ playerName }}</div>
  </v-container>
</template>

<script>
export default {
  props: {
    activePlayerId: { type: String },
    playerId: { type: String },
    gamePiece: { type: String },
    playerName: { type: String },
  },
  computed: {
    gamePieceIcon: function() {
      return `mdi-${this.gamePiece}`;
    },
  },
};
</script>

<style>
.active {
  border-bottom: solid 4px var(--v-accent-base);
}
</style>
