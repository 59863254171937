module.exports = {
  errorMessages: {
    duplicateName: "Name already taken",
    pageNotFound: "Page not found",
    gameExpired: "This game has expired",
    genericError: "There was an error",
  },
  gameMessages: {
    waitingOnPlayer: "Waiting on player joining",
  },
  shareMessage: {
    title: "Invite to play TicTacTwo",
  },
};
