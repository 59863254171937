<template>
  <span>{{ timerValue }}</span>
</template>

<script>
const { Timer } = require("timer-node");

export default {
  props: {
    startTime: { type: Date },
  },
  data() {
    return {
      timerValue: "",
      timer: null,
    };
  },
  mounted() {
    this.startMatchTimer();
  },
  methods: {
    startMatchTimer: function () {
      this.timer = new Timer({
        label: "match-timer",
        startTimestamp: this.startTime.getTime(),
      });

      this.timer.start();

      setInterval(() => {
        let tempValue = "";

        // Hours
        if (this.timer.time().h > 0) {
          tempValue += this.timer.time().h + ":";
        }

        // Minutes
        tempValue += twoDigitFormat(this.timer.time().m) + ":";

        // Seconds
        tempValue += twoDigitFormat(this.timer.time().s);

        this.timerValue = tempValue;
      }, 1000);
    },
  },
  watch: {
    startTime: function () {
      this.timer.clear();

      setTimeout(() => {
        this.startMatchTimer();
      }, this.startTime.getTime() - new Date().getTime());
    },
  },
};

function twoDigitFormat(value) {
  if (value < 10) {
    return "0" + value;
  }
  return value;
}
</script>
