function storeData(key, data) {
  return sessionStorage.setItem(key, data);
}

function getData(key) {
  return sessionStorage.getItem(key);
}

function deleteData(key) {
  return sessionStorage.removeItem(key);
}

export default {
  storeData,
  getData,
  deleteData,
};
