<template>
  <div>
    <div v-if="!gameState">Loading</div>
    <div v-else class="mt-6">
      <!-- Carousel of all matches -->
      <v-carousel
        v-model="carouselIndex"
        :continuous="false"
        :show-arrows="false"
        :hide-delimiters="
          !showCarouselDelimiters || gameState.data.matches.length < 2
        "
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        ref="carousel"
      >
        <v-carousel-item v-for="(match, i) in gameState.data.matches" :key="i">
          <div class="text-center mb-2">
            <div
              class="text-subtitle-1"
              :class="{
                'secondary--text': !match.isMatchOver,
                'text--text': match.isMatchOver,
              }"
            >
              {{ getGameTitle(i, match) }}
            </div>
          </div>

          <grid-group
            :matchIndex="i"
            :boardProp="match.board"
            :miniBoardSummary="match.miniBoardSummary"
            :gamePieces="gamePieces"
            :winPath="match.winPath"
            :isWaiting="!isGameReady"
            @on-cell-click="onCellClicked"
          />
          <div class="mt-4">
            <loading-bar
              v-if="loadingBarConfig.isShow"
              :label="loadingBarConfig.label"
              :endTime="loadingBarConfig.endTime"
              @loading-complete="onLoadingBarComplete"
            />
          </div>
        </v-carousel-item>
      </v-carousel>

      <!-- Scoreboard -->
      <scoreboard
        v-if="gameState && gameState.players.length > 0"
        :gameState="gameState"
        :playerId="playerId"
        :showMatchTimer="true"
        :showSummaryButton="showGoToSummaryButton"
        @show-summary="showGameSummary"
      />

      <!-- Summary dialog -->
      <game-summary-dialog
        :showDialog="showSummaryDialog"
        :gameState="gameState"
        :gamePieces="gamePieces"
        @close-dialog="showSummaryDialog = false"
      />
    </div>

    <!-- Tutorial dialog -->
    <v-dialog v-model="tutorialDialog" persistent max-width="344">
      <div class="pa-4 background-light">
        <Tutorial @close-tutorial="closeTutorial" />
      </div>
    </v-dialog>

    <!-- Leave game dialog -->
    <v-dialog v-model="showLeaveGameDialog" max-width="290">
      <v-card light color="text">
        <v-card-title class="text-subtitle2"> Leave Game? </v-card-title>
        <v-card-actions>
          <v-btn text @click="showLeaveGameDialog = false"> No, stay </v-btn>
          <v-btn color="accent" text @click="confirmLeaveGame">
            Yes, leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GridGroup from "./GridGroup.vue";
import websockets from "../assets/js/websockets";
import { socketNamespaces } from "../assets/js/app-settings";
import storageManager from "../assets/js/storage-manager";
import { EventBus } from "../plugins/event-bus";
import Scoreboard from "./Scoreboard.vue";
import LoadingBar from "./LoadingBar.vue";
import GameSummaryDialog from "./GameSummaryDialog.vue";
import Tutorial from "./Tutorial.vue";
let socket;

export default {
  components: {
    GridGroup,
    Scoreboard,
    LoadingBar,
    GameSummaryDialog,
    Tutorial
  },
  props: {
    gameId: { type: String },
  },
  data() {
    return {
      carouselIndex: 0,
      playerId: null,
      gameState: null,
      loadingBarConfig: {
        isShow: false,
        label: "",
        endTime: null,
      },
      showCarouselDelimiters: true,
      showSummaryDialog: false,
      showLeaveGameDialog: false,
      showGoToSummaryButton: false,
      gameSummaryDelay: 1800,
      gamePieceDraw: "minus",
      leaveGameOnClickEvent: "leaveGameOnClick",
      tutorialDialog: true,
    };
  },
  methods: {
    initialiseWebSockets: function () {
      socket = websockets.initialiseSocket(socketNamespaces.game, {
        query: {
          token: storageManager.getData(`gameAuthToken.${this.gameId}`),
        },
      });

      this.listenForSocketEvents();
    },
    listenForSocketEvents: function () {
      socket.on("subscribeSuccess", (data) => {
        this.onSubscribeSuccess(data);
      });

      socket.on("playerJoined", (data) => {
        this.onPlayerJoined(data);
      });

      socket.on("gameStart", (data) => {
        this.onGameStart(data);
      });

      socket.on("gameChange", (data) => {
        this.onBoardChange(data);
      });

      socket.on("matchComplete", (data) => {
        this.onMatchWin(data);
      });
    },
    onSubscribeSuccess: function ({ gameDoc, playerId }) {
      this.gameState = { ...gameDoc };
      this.playerId = playerId;

      // Show the last game in carousel
      this.carouselIndex = this.currentMatchIndex;

      // Show game summary if game has finished
      if (gameDoc.data.isGameOver) {
        this.showGameSummary();
      }
    },
    onCellClicked: function ({ matchIndex, boardIndex, cellIndex }) {
      // Check if game is ready
      if (!this.isGameReady) {
        return;
      }

      // Check if it is this player's turn
      if (!this.checkIfCurrentPlayer()) {
        return;
      }

      // Check if it is a valid move
      if (!this.checkValidMatchAndMove({ matchIndex, boardIndex, cellIndex })) {
        return;
      }

      websockets.broadcastData(socketNamespaces.game, "playerEvent", {
        eventName: "playerMove",
        playerMove: [boardIndex, cellIndex],
      });
    },
    closeTutorial: function () {
      this.tutorialDialog = false;
    },
    onPlayerJoined: function ({ gamePlayers }) {
      // Update local cache of gameState players
      this.gameState.players = gamePlayers;

      // Notify scoreboard of changes
      EventBus.$emit("scoreboardChange", {});
    },
    onGameStart: function ({ startTime }) {
      if (this.gameState) {
        this.gameState.data.matches[0].startTime = startTime;
      }
    },
    onBoardChange: function ({ currentMatch, allData }) {
      // Update local cache of gameState data
      if (allData) {
        this.gameState.data = allData;

        if (this.gameState.data.isGameOver) {
          setTimeout(() => {
            this.showGameSummary();
          }, this.gameSummaryDelay);
        } else {
          this.showNextMatch();
        }
      } else {
        this.gameState.data.matches[this.currentMatchIndex] = currentMatch;
      }

      // Notify miniBoards of changes
      EventBus.$emit("boardChange", this.getBoardChangeFromHistory());

      // Notify scoreboard of changes
      EventBus.$emit("scoreboardChange", {});
    },
    getGameTitle: function (matchIndex, matchData) {
      let gameTitle = `Game ${matchIndex + 1}`;

      if (matchData.isMatchOver) {
        if (matchData.winner) {
          gameTitle += ` - ${this.getPlayerNameById(matchData.winner)} win`;
        } else {
          gameTitle += ` - Draw`;
        }
      }
      return gameTitle;
    },
    onMatchWin: function ({ matchIndex, winPath }) {
      // Notify boards of winning path
      EventBus.$emit("matchWin", { matchIndex, winPath });
    },
    getBoardChangeFromHistory: function () {
      const historyItem =
        this.gameState.data.matches[this.currentMatchIndex].history[
          this.gameState.data.matches[this.currentMatchIndex].history.length - 1
        ];

      if (!historyItem) {
        return {};
      }

      return {
        matchIndex: this.currentMatchIndex,
        boardIndex: historyItem.move[0],
        cellIndex: historyItem.move[1],
        miniBoard:
          this.gameState.data.matches[this.currentMatchIndex].board[
            historyItem.move[0]
          ],
        miniBoardSummary:
          this.gameState.data.matches[this.currentMatchIndex].miniBoardSummary,
      };
    },
    checkIfCurrentPlayer: function () {
      const currentPlayer =
        this.gameState.players[
          this.gameState.data.matches[this.currentMatchIndex].currentPlayerIndex
        ];
      return currentPlayer && this.playerId === currentPlayer._id;
    },
    checkValidMatchAndMove: function ({ matchIndex, boardIndex, cellIndex }) {
      // Check if match is already over
      if (this.gameState.data.matches[matchIndex].isMatchOver) {
        return false;
      }

      return checkIfValidMove(this.gameState, {
        matchIndex,
        boardIndex,
        cellIndex,
      });
    },
    showNextMatch: function () {
      this.showLoadingBar(
        new Date(this.gameState.data.matches[this.currentMatchIndex].startTime),
        this.gameState.data.matches.length
      );
    },
    showGameSummary: function () {
      setTimeout(() => {
        this.showSummaryDialog = true;
        this.showGoToSummaryButton = true;
      }, 10);
    },
    showLoadingBar: function (endTime, matchNum) {
      this.showCarouselDelimiters = false;

      this.loadingBarConfig = {
        isShow: true,
        label: `Starting match ${matchNum}...`,
        endTime: endTime,
      };
    },
    onLoadingBarComplete: function () {
      this.loadingBarConfig = {
        isShow: false,
        label: "",
        endTime: null,
      };

      this.showCarouselDelimiters = true;
      this.carouselIndex = this.gameState.data.matches.length - 1;
    },
    getPlayerNameById: function (playerId) {
      for (let i = 0; i < this.gameState.players.length; i++) {
        if (this.gameState.players[i]._id === playerId) {
          return this.gameState.players[i].name;
        }
      }
    },
    onLeaveGame: function () {
      this.showLeaveGameDialog = true;
    },
    confirmLeaveGame: function () {
      storageManager.deleteData(`gameAuthToken.${this.gameId}`);
      this.$router.push({ name: "CreateGame", params: {} });

      // Emit event to remove "Leave Game" button from nav drawer
      EventBus.$emit("navDrawerActionButtonHide", {});
    },
  },
  computed: {
    gamePieces: function () {
      let gamePieces = [];
      for (let i = 0; i < this.gameState.players.length; i++) {
        gamePieces.push(this.gameState.players[i].piece);
      }

      // Append the game peice for a draw
      gamePieces.push(this.gamePieceDraw);

      return gamePieces;
    },
    currentMatchIndex: function () {
      return this.gameState.data.matches.length - 1;
    },
    isGameReady: function () {
      if (
        this.gameState.players.length === this.gameState.config.totalPlayers
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    // Initialise the /game namespace for sending/receiving game events
    this.initialiseWebSockets();

    // Subscribe to game events
    websockets.broadcastData(socketNamespaces.game, "joinGame", {});

    // Add "Leave Game" button to navigation drawer
    EventBus.$emit("navDrawerActionButtonShow", {
      label: "Leave Game",
      clickEvent: this.leaveGameOnClickEvent,
    });

    // Listen for "Leave Game" button click event
    EventBus.$on(this.leaveGameOnClickEvent, () => {
      this.onLeaveGame();
    });
  },
};

function checkIfValidMove(gameState, { matchIndex, boardIndex, cellIndex }) {
  const gameBoard = gameState.data.matches[matchIndex].board;

  // Check if valid indexes
  if (
    boardIndex < 0 ||
    boardIndex >= gameBoard.length ||
    cellIndex < 0 ||
    cellIndex >= gameBoard[boardIndex].length
  ) {
    return false;
  }

  // Check index has not already been used
  if (gameBoard[boardIndex][cellIndex] === null) {
    return true;
  }

  return false;
}
</script>

<style>
.loading-bar {
  color: var(--v-text-base);
}

.v-carousel {
  height: 475px !important;
}

@media only screen and (max-width: 385px) {
  .v-carousel {
    height: 400px !important;
  }
}

.background-light {
  background-color: #ffffff;
}
</style>
