/**
 * Outputs duration if format hours minutes seconds. eg: 2h 3m 38s
 * @param {*} startTime
 * @param {*} endTime
 * @param {*} _returnOnFirstUnit - Return time on the first unit > 0.
 * @returns
 */
function formatDuration(startTime, endTime, _returnOnFirstUnit) {
  let delta = Math.abs(new Date(endTime) - new Date(startTime)) / 1000;

  let duration = "";

  // Hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  if (hours > 0) {
    duration += `${hours}h `;

    if (_returnOnFirstUnit) {
      return duration.trim();
    }
  }

  // Minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  if (minutes > 0) {
    duration += `${minutes}m `;

    if (_returnOnFirstUnit) {
      return duration.trim();
    }
  }

  // Seconds
  const seconds = Math.round(delta % 60);
  if (seconds > 0) {
    duration += `${seconds}s`;

    if (_returnOnFirstUnit) {
      return duration.trim();
    }
  }

  return duration.trim();
}

export default {
  formatDuration,
};
