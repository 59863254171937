import Vue from "vue";
import Router from "vue-router";
import GamePage from "../components/GameManager.vue";
import GameCreation from "../components/CreateGame.vue";
import ErrorPage from "../components/ErrorPage";
import { appName } from "../assets/js/app-settings";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      name: "CreateGame",
      path: "/",
      component: GameCreation,
      meta: {
        title: "Create Game",
      },
    },
    {
      name: "GamePage",
      path: "/game/:urlId",
      component: GamePage,
      meta: {
        title: appName,
      },
    },
    {
      name: "ErrorPage",
      path: "*",
      component: ErrorPage,
      meta: {
        title: appName,
      },
    },
  ],
});

export default router;
