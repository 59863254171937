<template>
  <div>
    <div
      v-for="(row, r) in formattedBoard"
      class="grid-row"
      :key="`mg-row-${r}`"
    >
      <div
        v-for="(cell, c) in row"
        class="grid-cell"
        :class="{
          'm-grid-row-top': [0, 1, 2].includes(3 * r + c),
          'm-grid-col-right': [2, 5, 8].includes(3 * r + c),
          'm-grid-row-bottom': [6, 7, 8].includes(3 * r + c),
          'm-grid-col-left': [0, 3, 6].includes(3 * r + c),
          'highlighted-cell': highlightedCells.includes(3 * r + c),
        }"
        :key="`mg-cell-${c}-${cell}`"
      >
        <div class="grid-cell-editor" @click="onCellClick(r, c)">
          <span v-if="cell !== null">
            <v-icon
              class="player-piece"
              :class="{ highlight: highlightedIcon === 3 * r + c }"
              >{{ getGamePiece(cell) }}</v-icon
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAbsoluteCellIndex,
  groupCellsToRows,
} from "../assets/js/grid-functions";

import { EventBus } from "../plugins/event-bus";

export default {
  props: {
    matchIndex: { type: Number },
    boardIndex: { type: Number },
    boardProp: { type: Array },
    gamePieces: { type: Array },
    winPath: { type: Array },
  },
  data: () => ({
    formattedBoard: [],
    highlightedIcon: -1,
    highlightedCells: [],
  }),
  methods: {
    onCellClick: function (rowIndex, cellIndex) {
      this.$emit("on-cell-click", {
        matchIndex: this.matchIndex,
        boardIndex: this.boardIndex,
        cellIndex: getAbsoluteCellIndex(rowIndex, cellIndex),
      });
    },
    getGamePiece: function (playerIndex) {
      if (playerIndex === -1) {
        return `mdi-${this.gamePieces[this.gamePieces.length - 1]}`;
      }
      return `mdi-${this.gamePieces[playerIndex]}`;
    },
    setHighlightedIcon: function (cellIndex) {
      this.highlightedIcon = cellIndex;

      setTimeout(() => {
        this.highlightedIcon = -1;
      }, 500);
    },
  },
  mounted() {
    this.formattedBoard = groupCellsToRows(this.boardProp);

    // Highlight the winning path of this grid if it exists
    if (this.winPath && this.winPath.length > 0) {
      this.highlightedCells = this.winPath;
    }

    EventBus.$on(
      "boardChange",
      ({ matchIndex, boardIndex, cellIndex, miniBoard }) => {
        if (matchIndex === this.matchIndex && boardIndex === this.boardIndex) {
          this.formattedBoard = groupCellsToRows(miniBoard);

          this.setHighlightedIcon(cellIndex);
        }
      }
    );

    EventBus.$on("boardWin", ({ matchIndex, boardIndex, winPath }) => {
      if (matchIndex === this.matchIndex && boardIndex === this.boardIndex) {
        this.highlightedCells = winPath;
      }
    });
  },
};
</script>

<style lang="scss">
.grid-cell-editor {
  border: none;
  width: 20px;
  height: 20px;
  font-family: "Dosis", sans-serif;
  font-weight: bold;
  text-align: center;
  transition: all ease 1s;
}

.grid-cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid var(--v-secondary-base);
}

.grid-cell .v-icon {
  padding-bottom: 8px;
  color: var(--v-text-base);
  transition: color 1.5s ease-in-out;
  font-size: 22px;
  margin-left: -1px;
}

@media only screen and (max-width: 385px) {
  .grid-cell-editor {
    width: 17px;
    height: 17px;
  }

  .grid-cell {
    padding: 8px;
  }

  .grid-cell .v-icon {
    font-size: 18px;
  }
}

.grid-cell.highlighted-cell {
  background-color: var(--v-accent-base);
}

.grid-cell .v-icon.highlight {
  color: var(--v-accent-base);
}

.m-grid-row-top {
  border-top-color: var(--v-background-base);
}

.m-grid-col-left {
  border-left-color: var(--v-background-base);
}

.m-grid-row-bottom {
  border-bottom-color: var(--v-background-base);
}

.m-grid-col-right {
  border-right-color: var(--v-background-base);
}
</style>
