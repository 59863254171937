<template>
  <div class="py-6 text-center accent--text">
    <!-- Game Type -->
    <div class="mb-4">
      <img width="275" :src="gameImage" />
    </div>

    <template v-if="!showGameShareCard">
      <!-- Select number of matches -->
      <div class="mb-10">
        <div class="text-h6">Number of matches</div>
        <span v-for="x in matchCountOptions" :key="x" @click="numMatches = x">
          <v-btn
            class="ma-2 option-selector"
            :class="{ selected: numMatches === x }"
            outlined
            fab
            small
          >
            {{ x }}
          </v-btn>
        </span>
      </div>

      <!-- Create button -->
      <div>
        <v-btn
          color="accent"
          :loading="inProgress"
          :disabled="!isPlayButtonEnabled"
          @click="createGame"
        >
          Create Game
        </v-btn>
      </div>
    </template>

    <template v-else>
      <!-- Game share card -->
      <game-share-card
        :urlId="newGameData.urlId"
        :expiry="newGameData.expiry"
      />
    </template>

    <!-- List of recent matches -->
    <div class="mt-8 pa-4" v-if="allGames.length > 0">
      <div class="text-subtitle-2 mb-2">Recent games</div>
      <game-list :allGames="allGames"></game-list>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { restApiEndpoints, serverDomain } from "../assets/js/app-settings";
import storageManager from "../assets/js/storage-manager";
import GameShareCard from "./GameShareCard.vue";
import GameList from "./GameList.vue";

export default {
  components: { GameShareCard, GameList },
  data: () => ({
    inProgress: false,
    numMatches: 1,
    matchCountOptions: [1, 2, 3],
    showGameShareCard: false,
    newGameData: {},
    allGames: [],
  }),
  mounted() {
    this.allGames = this.getStoredGames();
  },
  computed: {
    gameImage: function () {
      const gameType = "ticTacTwo";
      switch (gameType) {
        case "ticTacTwo":
          return require("../assets/images/tic-tac-two.svg");

        default:
          return "";
      }
    },
    isPlayButtonEnabled: function () {
      return true;
    },
  },
  methods: {
    createGame: function () {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;

      const gameData = {
        gameType: "ticTacTwo",
        totalMatches: this.numMatches,
      };

      axios
        .post(serverDomain + restApiEndpoints.gameCreate, gameData)
        .then(({ status, data }) => {
          this.inProgress = false;

          if (status === 200) {
            this.newGameData = data;
            this.showGameShareCard = true;

            // Add to player's local store of games
            this.addToMyGames(data);
          } else {
            // Game creation failed
          }
        });
    },
    addToMyGames: function (gameData) {
      let allGames = this.getStoredGames();

      // Add this newly created game
      gameData.createdDate = new Date();
      allGames.push(gameData);

      // Store game list
      storageManager.storeData(`myGames`, JSON.stringify(allGames));
    },
    getStoredGames: function () {
      // Get currently stored list of games
      let allGames = storageManager.getData(`myGames`);
      if (!allGames) {
        allGames = [];
      } else {
        allGames = JSON.parse(allGames);
      }

      // Remove any games which have expired
      if (allGames.length > 0) {
        let removeCount = 0;
        for (let i = 0; i < allGames.length; i++) {
          if (new Date(allGames[i].expiry) <= new Date()) {
            allGames.splice(i, 1);
            removeCount++;
          }
        }

        if (removeCount > 0) {
          // Store game list
          storageManager.storeData(`myGames`, JSON.stringify(allGames));
        }
      }

      // Sort games by createdDate desending
      allGames.sort(function (a, b) {
        return new Date(b.createdDate) - new Date(a.createdDate);
      });

      return allGames;
    },
  },
};
</script>

<style>
.v-btn.option-selector {
  font-size: 18px;
  color: var(--v-text-base);
  border: 2px solid var(--v-text-base);
}

.v-btn.option-selector.selected {
  border-color: var(--v-accent-base);
}
</style>
