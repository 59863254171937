<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="5">
          <div>
            <mini-grid
              :matchIndex="matchIndex"
              :boardIndex="null"
              :boardProp="matchData.miniBoardSummary"
              :gamePieces="gamePieces"
              :winPath="matchData.winPath"
            />
          </div>
        </v-col>
        <v-col cols="7" class="text-left text--text">
          <!-- Match Index -->
          <div class="text-h6 mb-5">
            <v-chip color="accent" label small
              >Match {{ matchIndex + 1 }}</v-chip
            >
          </div>

          <div>
            <!-- Match result -->
            <v-row>
              <v-col cols="5" class="py-1">
                <div class="text-body-2">Winner:</div>
              </v-col>
              <v-col cols="7" class="py-1">
                <div class="text-body-2">
                  {{ matchWinner }}
                </div>
              </v-col>
            </v-row>

            <!-- Match duration -->
            <v-row>
              <v-col cols="5" class="py-1">
                <div class="text-body-2">Time:</div>
              </v-col>
              <v-col cols="7" class="py-1">
                <div class="text-body-2">{{ gameDuration }}</div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import MiniGrid from "./MiniGrid.vue";
import utilityFunctions from "../assets/js/utility-functions";

export default {
  components: { MiniGrid },
  props: {
    matchIndex: { type: Number },
    players: { type: Object },
    gamePieces: { type: Array },
    matchData: { type: Object },
  },
  computed: {
    gameDuration: function () {
      return utilityFunctions.formatDuration(
        this.matchData.startTime,
        this.matchData.endTime
      );
    },
    matchWinner: function () {
      if (this.matchData.isDraw) {
        return "Draw";
      }
      return this.players[this.matchData.winner].name;
    },
  },
};
</script>

<style>
.theme--light.v-sheet.v-card {
  background-color: var(--v-background-base);
  border: solid 1px var(--v-secondary-base);
}
</style>
