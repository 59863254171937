var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-group"},[_vm._l((_vm.formattedBoard),function(row,r){return _c('v-row',{key:("gg-row-" + r),staticClass:"grid-row",attrs:{"no-gutters":""}},_vm._l((row),function(cell,c){return _c('v-col',{key:("gg-cell-" + c),staticClass:"mini-grid",class:{
        'grid-row-top': [0, 1, 2].includes(3 * r + c),
        'grid-col-right': [2, 5, 8].includes(3 * r + c),
        'grid-row-bottom': [6, 7, 8].includes(3 * r + c),
        'grid-col-left': [0, 3, 6].includes(3 * r + c),
        'highlighted-grid': _vm.highlightedGrids.includes(3 * r + c),
      }},[(
          _vm.moveCounter &&
          _vm.miniBoardSummary[3 * r + c] !== null &&
          _vm.miniBoardSummary[3 * r + c] !== -1
        )?_c('div',{staticClass:"text-center full-grid-icon"},[_c('v-icon',[_vm._v(_vm._s(_vm.getGamePiece(_vm.miniBoardSummary[3 * r + c])))])],1):_c('mini-grid',{attrs:{"matchIndex":_vm.matchIndex,"boardIndex":_vm.getAbsoluteCellIndex(r, c),"boardProp":cell,"gamePieces":_vm.gamePieces},on:{"on-cell-click":_vm.onCellClick}})],1)}),1)}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.isWaiting,"opacity":0.8,"color":"secondary"}},[_c('waiting-animation',{staticClass:"mb-3"}),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.waitingOnPlayerMessage))])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }