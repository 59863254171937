<template>
  <div class="py-6 text-center accent--text">
    <v-row>
      <v-spacer></v-spacer>

      <!-- URL Id -->
      <v-col :cols="8">
        <div class="text-h6 secondary--text">
          {{ `#${this.gameData.urlId}` }}
        </div>
      </v-col>

      <!-- Share Icon -->
      <v-col :cols="2" align="right">
        <v-btn text x-small @click="openShareDialog">
          <v-icon color="accent" class="mr-1">mdi-share-variant</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Game Type -->
    <div class="mb-4">
      <img width="275" :src="gameImage" />
    </div>

    <div v-if="isLobbyOpen">
      <!-- Enter player name -->
      <div class="mb-6">
        <div class="text-h6 mb-2">Enter a player name</div>
        <v-text-field
          v-model="playerName"
          :required="true"
          solo
          class="text-center"
          hide-details="auto"
          :placeholder="`Max ${playerNameMaxLength} characters`"
          :maxlength="playerNameMaxLength"
          @input="onPlayerNameChange"
        ></v-text-field>

        <!-- Player name error message -->
        <v-alert
          v-if="playerNameErrorMessage !== ''"
          type="error"
          class="mt-2"
          dense
          >{{ playerNameErrorMessage }}</v-alert
        >
      </div>

      <!-- Select game piece -->
      <div class="mb-10">
        <div class="text-h6">Select your game piece</div>
        <span
          v-for="{ label, isAvailable } in gamePieceOptions"
          :key="label"
          @click="
            if (isAvailable) {
              gamePiece = label;
            }
          "
        >
          <v-icon
            large
            class="pa-2 game-pieces"
            :class="{ selected: gamePiece === label, disabled: !isAvailable }"
            >{{ `mdi-${label}` }}</v-icon
          >
        </span>
      </div>

      <!-- Login button -->
      <div>
        <v-btn
          color="accent"
          :loading="inProgress"
          :disabled="!isPlayButtonEnabled"
          @click="loginToGame"
        >
          Play
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-container class="text--text mt-8 pa-0">
        <v-row justify="center">
          <v-col cols="5" class="pa-0">
            <player-card
              :playerId="this.gameData.players[0]._id"
              :playerName="this.gameData.players[0].name"
              :gamePiece="this.gameData.players[0].piece"
            />
          </v-col>
          <v-col cols="2" class="text-center">
            <div class="text-h5 mt-2">vs</div>
          </v-col>
          <v-col cols="5" class="pa-0">
            <player-card
              :playerId="this.gameData.players[1]._id"
              :playerName="this.gameData.players[1].name"
              :gamePiece="this.gameData.players[1].piece"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import PlayerCard from "./PlayerCard.vue";
import axios from "axios";
import {
  maxPlayerNameLength,
  restApiEndpoints,
  serverDomain,
} from "../assets/js/app-settings";
import { errorMessages, shareMessage } from "../assets/js/app-strings";
import { socketNamespaces } from "../assets/js/app-settings";
import websockets from "../assets/js/websockets";
import storageManager from "../assets/js/storage-manager";
let socket;

export default {
  components: { PlayerCard },
  props: { gameData: { type: Object } },
  data: () => ({
    playerName: "",
    playerNameErrorMessage: "",
    playerNameMaxLength: maxPlayerNameLength,
    usedPlayerNames: [],
    gamePiece: "",
    inProgress: false,
    gamePieceOptions: [
      { label: "close-box", isAvailable: true },
      { label: "circle-outline", isAvailable: true },
      { label: "heart", isAvailable: true },
      { label: "star", isAvailable: true },
      { label: "paw", isAvailable: true },
    ],
  }),
  computed: {
    gameImage: function () {
      switch (this.gameData.config.gameType) {
        case "ticTacTwo":
          return require("../assets/images/tic-tac-two.svg");

        default:
          return "";
      }
    },
    isPlayButtonEnabled: function () {
      return (
        this.playerName &&
        this.playerName.trim() !== "" &&
        this.usedPlayerNames.includes(
          this.formatPlayerName(this.playerName)
        ) === false &&
        this.gamePiece !== ""
      );
    },
    isLobbyOpen: function () {
      return this.gameData.players.length < this.gameData.config.totalPlayers;
    },
  },
  mounted() {
    // Initialise the /lobby namespace for sending/receiving game events
    this.initialiseWebSockets();

    // Subscribe to lobby events
    websockets.broadcastData(socketNamespaces.lobby, "joinLobby", {
      gameId: this.gameData._id,
    });

    // Check if game lobby is open
    if (this.isLobbyOpen) {
      this.onPlayerJoined(this.gameData.players);
    }
  },
  methods: {
    initialiseWebSockets: function () {
      socket = websockets.initialiseSocket(socketNamespaces.lobby, {});
      this.listenForSocketEvents();
    },
    listenForSocketEvents: function () {
      socket.on("playerJoined", ({ gamePlayers }) => {
        this.onPlayerJoined(gamePlayers);
      });
    },
    onPlayerJoined: function (gamePlayers) {
      for (let j = 0; j < gamePlayers.length; j++) {
        const { name, piece } = gamePlayers[j];

        // Add player name to usedPlayerNames
        if (this.usedPlayerNames.includes(name) === false) {
          this.usedPlayerNames.push(name);
        }

        // Disable game-pieces if they are used
        for (let i = 0; i < this.gamePieceOptions.length; i++) {
          if (this.gamePieceOptions[i].label === piece) {
            this.gamePieceOptions[i].isAvailable = false;
          }
        }
      }

      this.gameData.players = gamePlayers;
    },
    loginToGame: function () {
      if (this.inProgress) {
        return;
      }

      this.inProgress = true;

      const playerData = {
        gameId: this.gameData._id,
        name: this.formatPlayerName(this.playerName),
        gamePiece: this.gamePiece,
      };

      axios
        .post(serverDomain + restApiEndpoints.gameLogin, playerData)
        .then(({ data }) => {
          this.inProgress = false;

          if (data.status === 200) {
            storageManager.storeData(
              `gameAuthToken.${this.gameData._id}`,
              data.token
            );

            this.$emit("playerLoggedIn", {});
          } else {
            // Login failed
            switch (data.message) {
              case "duplicate-name":
                this.usedPlayerNames.push(playerData.name);
                this.playerNameErrorMessage = errorMessages.duplicateName;
                break;

              default:
                break;
            }
          }
        });
    },
    formatPlayerName: function (playerName) {
      // Trim whitespace
      playerName = playerName.trim();

      // Replace any multiple whitespaces
      playerName = playerName.replace(/\s\s+/g, " ");

      return playerName;
    },
    onPlayerNameChange: function () {
      if (
        this.usedPlayerNames.includes(this.formatPlayerName(this.playerName))
      ) {
        this.playerNameErrorMessage = errorMessages.duplicateName;
      } else {
        this.playerNameErrorMessage = "";
      }
    },
    openShareDialog() {
      navigator.share({
        title: shareMessage.title,
        url: this.$router.currentRoute.fullPath,
      });
    },
  },
};
</script>

<style scoped>
.v-icon.game-pieces {
  color: var(--v-text-base);
  border-bottom: 3px solid transparent;
}

.v-icon.game-pieces.selected {
  border-bottom-color: var(--v-accent-base);
}

.v-icon.game-pieces.disabled {
  color: var(--v-secondary-base);
}
</style>

<style>
.v-input.text-center input {
  text-align: center;
}
</style>
