import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { appName } from "./assets/js/app-settings";

Vue.config.productionTip = false;

new Vue({
  router, // we tell our vue instance to use this vue router
  vuetify,
  render: (h) => h(App),
  watch: {
    // Set page titles
    $route: {
      handler: (to) => {
        document.title = to.meta.title || appName;
      },
      immediate: true,
    },
  },
}).$mount("#app");
