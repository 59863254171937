<template>
  <v-dialog transition="dialog-bottom-transition" fullscreen v-model="dialog">
    <v-card color="primary">
      <v-toolbar dark
        ><div class="text-h6 ma-auto">{{ gameResult }}</div></v-toolbar
      >
      <v-card-text>
        <!-- Scoreboard -->
        <div class="mt-6">
          <scoreboard :gameState="gameState" :showCurrentPlayer="false" />
        </div>

        <!-- Match cards -->
        <div class="mt-3">
          <template v-for="(match, i) in gameState.data.matches">
            <game-summary-card
              class="mb-3"
              :key="`game-summary-${i}`"
              :matchIndex="i"
              :matchData="match"
              :gamePieces="gamePieces"
              :players="playersById"
            />
          </template>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn text color="accent" @click="closeDialog"
          >Return to game board</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Scoreboard from "./Scoreboard.vue";
import GameSummaryCard from "./GameSummaryCard.vue";

export default {
  components: { Scoreboard, GameSummaryCard },
  props: {
    showDialog: { type: Boolean, default: false },
    playerId: { type: String },
    gameState: { type: Object },
    gamePieces: { type: Array },
  },
  data() {
    return { dialog: false };
  },
  methods: {
    closeDialog: function () {
      this.$emit("close-dialog", {});
      this.dialog = false;
    },
    getPlayerNameById: function (playerId) {
      for (let i = 0; i < this.gameState.players.length; i++) {
        if (this.gameState.players[i]._id === playerId) {
          return this.gameState.players[i].name;
        }
      }
    },
  },
  computed: {
    playersById: function () {
      let playersObj = {};
      for (let i = 0; i < this.gameState.players.length; i++) {
        playersObj[this.gameState.players[i]._id] = this.gameState.players[i];
      }
      return playersObj;
    },
    gameResult: function () {
      if (this.gameState.data.winner) {
        return `${this.playersById[this.gameState.data.winner].name} wins`;
      }
      return "";
    },
  },
  watch: {
    showDialog: function () {
      this.dialog = this.showDialog;
    },
  },
};
</script>
