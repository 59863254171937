<template>
  <div>
    <div class="text-h6 mb-3 text-center">How to Play</div>
    <div>
      <v-carousel
        v-model="activeStep"
        :show-arrows="false"
        :hide-delimiters="true"
        light
      >
        <v-carousel-item
          v-for="(item, i) in tutorialItems"
          :key="`tutorial-item-${i}`"
        >
          <v-img :src="getImageSrc(i, item.activeImageIndex)"></v-img>

          <div class="mt-4">
            {{ item.caption }}
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div>
      <v-row>
        <!-- Back button (navigate back 1 step) -->
        <v-col :cols="6">
          <v-btn
            text
            small
            @click="navigateSteps('previous')"
            :disabled="activeStep === 0"
          >
            <v-icon color="accent" class="mr-1">mdi-chevron-left</v-icon>
            <span>Back</span>
          </v-btn>
        </v-col>

        <!-- Next/Finish button (navigate forward 1 step)-->
        <v-col :cols="6" align="right">
          <v-btn text small @click="navigateSteps('next')">
            <span>{{
              activeStep === tutorialItems.length - 1 ? "Finish" : "Next"
            }}</span>
            <v-icon color="accent" class="mr-1">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- Preload images -->
    <div v-for="(item, i) in tutorialImages" :key="`tutorial-images-${i}`">
      <img
        v-for="(image, j) in item"
        :key="`tutorial-image-${i}-${j}`"
        :src="getImageSrc(i, j)"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      activeStep: 0,
      imageCycleInterval: 1000,
      tutorialItems: [
        {
          activeImageIndex: 0,
          imageSrc: "",
          caption:
            "The first player to get 3 of their pieces in a row within a 3x3 grid wins that particular grid. The winning player's game piece will then fill the whole 3x3 grid.",
        },
        {
          activeImageIndex: 0,
          imageSrc: "",
          caption:
            "The aim of the game is to get 3 game pieces in a row by winning three 3x3 grids in a row.",
        },
      ],
      tutorialImages: [
        [
          "tutorial-01.svg",
          "tutorial-02.svg",
          "tutorial-03.svg",
          "tutorial-04.svg",
          "tutorial-05.svg",
          "tutorial-06.svg",
          "tutorial-07.svg",
          "tutorial-08.svg",
        ],
        [
          "tutorial-08.svg",
          "tutorial-09.svg",
          "tutorial-10.svg",
          "tutorial-11.svg",
          "tutorial-12.svg",
          "tutorial-13.svg",
        ],
      ],
      cycleTimers: [],
    };
  },
  methods: {
    navigateSteps: function (direction) {
      if (direction === "next") {
        this.activeStep++;
      } else {
        this.activeStep--;
      }

      if (this.activeStep >= this.tutorialItems.length) {
        // Close tutorial
        // Emit event to parent component
        this.$emit("close-tutorial");
      } else {
        this.cycleImageSrcs(this.activeStep);
      }
    },
    getImageSrc: function (tutorialStepIndex, imageIndex) {
      return require("../assets/images/tutorial-images/" +
        this.tutorialImages[tutorialStepIndex][imageIndex]);
    },
    cycleImageSrcs: function (tutorialStepIndex) {
      // Clear previous cycleTimers
      for (let i = 0; i < this.cycleTimers.length; i++) {
        const element = this.cycleTimers[i];

        if (element) {
          clearInterval(element);
        }
      }

      const maxCount = this.tutorialImages[tutorialStepIndex].length;
      let imageCounter = 0;
      let interval = setInterval(() => {
        if (imageCounter >= maxCount) {
          imageCounter = -1;
        }

        imageCounter++;

        this.tutorialItems[tutorialStepIndex].activeImageIndex = imageCounter;
      }, this.imageCycleInterval);

      this.cycleTimers[tutorialStepIndex] = interval;
    },
  },
  mounted() {
    this.activeStep = 0;

    // Start cycling images on 1st tutorial step
    this.cycleImageSrcs(0);
  },
};
</script>
