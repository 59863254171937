<template>
  <v-progress-linear :value="value" color="secondary" height="25">
    <template>
      <span class="loading-bar">{{ label }}</span>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  props: {
    endTime: { type: Date },
    label: { type: String },
  },
  data: () => ({
    value: 0,
  }),
  mounted() {
    const intervalMillis = 50;
    const maxValue = 100;

    const incrementPerSec =
      (maxValue / (this.endTime.getTime() - new Date().getTime())) *
      intervalMillis;

    let valueTimer = setInterval(() => {
      this.value += incrementPerSec;

      if (this.value >= maxValue) {
        this.$emit("loading-complete", true);
        clearInterval(valueTimer);
      }
    }, intervalMillis);
  },
};
</script>
