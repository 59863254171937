module.exports = {
  appName: "Tic Tac Two",
  serverDomain: "https://tic-tac-two.eu-4.evennode.com",
  // serverDomain: "http://localhost:3000",
  socketNamespaces: {
    game: "/game",
    lobby: "/lobby",
  },
  restApiEndpoints: {
    gameFetch: "/game/:urlId",
    gameCreate: "/game-create",
    gameLogin: "/game-login",
    gameLoginCheck: "/game-login-check",
  },
  maxPlayerNameLength: 20,
};
